.DialerTimeCall {
  background-color: #e7eaee;
  width: 150px;
  height: 55px;
  margin: 0 auto;
  border-radius: 100px;
  font-size: xx-large;
  font-weight: 400;
  font-family: Roboto;
  color: #262626;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DialerTimeText {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-size: large;
  font-weight: 500;
  min-height: 23px;
  color: #262626;
}

.TimerContent {
  height: 270px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.typingAnimation {
  overflow: hidden;
  text-align: left;
  animation: typing 2s linear infinite;
}

@keyframes typing {
  0% {
    margin: 0px;
  }

  33% {
    width: 5px;
  }

  66% {
    width: 10px;
  }

  100% {
    width: 15px;
  }
}
