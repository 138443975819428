.ButtonMute,
.ButtonToKeypad {
  display: grid;
  cursor: pointer;
  width: 54px;
  height: 48px;
  font-size: 15px;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
  color: #262626;
}

.ButtonMute span,
.ButtonToKeypad svg {
  font-size: 24px;
}

.ButtonMute:hover,
.ButtonToKeypad:hover,
.ButtonPressed {
  background-color: #e7eaee;
}

.ButtonKeypad {
  cursor: pointer;
  width: 50px;
  border-radius: 50%;
  padding: 5px 10px 5px 10px;
  font-size: 14px;
}

.ButtonKeypad:hover {
  background-color: #e7eaee;
}

.ButtonTurnOff {
  transform: rotate(135deg);
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff2a56;
  border-radius: 50%;
  margin: 0px auto;
  color: #fff;
  font-size: 26px;
  cursor: pointer;
  position: relative;
}

.ButtonTurnOff:hover {
  background-color: #d40b4b;
}

.ButtonTurnOn {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2cbe86;
  border-radius: 50%;
  margin: 0px auto;
  color: #fff;
  font-size: 26px;
  cursor: pointer;
  position: relative;
  top: 70px;
}

.NumberButtonKeypad {
  cursor: pointer;
  width: 100%;
  font-size: 26px;
  font-weight: 600;
  color: #262626;
}

.LettersButtonKeypad {
  color: #262626;
}
