.NumberCallPopupContainer {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 70px;
  height: 30px;
  width: max-content;
  min-width: 136px;
  margin: auto;
  border: solid 1px #262626;
  background-color: #262626;
  color: #ffffff;
  padding: 6px 10px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
}

.NumberCallPopupArrow {
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 29px;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #262626;
}
