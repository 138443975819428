.ContainerPage {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.IconCantCall {
  width: 68px;
  height: 68px;
  position: relative;
  margin: auto;
  padding: 15px;
}

.TextCantCall {
  width: 180px;
  position: relative;
  margin: 0 auto;
  color: #262626;
}
